@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  overflow-x: hidden;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-1 {
  padding: 0rem;
}
.prose {
  font-size: 14px;
}
select {
  -webkit-appearance: none;
}
.nav-container::-webkit-scrollbar {
  width: 0.1rem;
}

.nav-container::-webkit-scrollbar-thumb {
  background: transparent;
}

.nav-container::-webkit-scrollbar-track {
  background: transparent;
}

.nav-container {
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.custom-shadow {
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.05);
}
.loading-screen-linear-gradient {
  background: linear-gradient(
    105deg,
    rgba(72, 117, 246, 0) 4.83%,
    rgba(72, 117, 246, 0.68) 26.44%,
    rgba(72, 117, 246, 0.8) 50.73%,
    rgba(72, 117, 246, 0.68) 70.42%,
    rgba(72, 117, 246, 0) 93.68%
  );
}
.gpt-field {
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  background: #fff;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.06);
}
.text-field {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 7px 15px 0px rgba(212, 219, 239, 0.5);
}
@font-face {
  font-family: "Helvetica";
  src: url("./assets/fonts/Helvetica.ttf") format("truetype");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("./assets/fonts/HelveticaNeueLight.otf") format("opentype");
}
@font-face {
  font-family: "Helvetica Neue Medium";
  src: url("./assets/fonts/HelveticaNeueMedium.ttf") format("opentype");
}

@font-face {
  font-family: "Mulish";
  src: url("./assets/fonts/Mulish.ttf") format("truetype");
}

@font-face {
  font-family: "Grotesque";
  src: url("./assets/fonts/random-grotesque.standard-bold.ttf")
    format("truetype");
}
