.new-thread-container::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: transparent !important;
  border-radius: 100px !important;
}

.new-thread-container::-webkit-scrollbar-thumb {
  background-color: #e2e9fe !important;
  border-radius: 100px !important;
}

.new-thread-container::-webkit-scrollbar {
  display: block;
}
.thread-gradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(240, 244, 254, 0.5) 100%
  );
}
