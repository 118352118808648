.gpt-text-container::-webkit-scrollbar {
  width: 8px;
  background-color: transparent !important;
  border-radius: 100px !important;
}

.gpt-text-container::-webkit-scrollbar-thumb {
  background-color: #e2e9fe !important;
  border-radius: 100px !important;
}

.gpt-text-container::-webkit-scrollbar-thumb:hover {
  background-color: #666668 !important;
}
.gpt-text-container::-webkit-scrollbar {
  display: block;
}
.text-break {
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}
