::-webkit-scrollbar {
  width: 5px;
  background-color: #202123;
}

::-webkit-scrollbar-thumb {
  background-color: #555555;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666668;
}
